
.horizontal-toolbar-container {
    width: 100%;
    overflow: hidden;
  }
  
  .horizontal-toolbar {
    animation: scrolling 90s linear infinite;
  }

  @keyframes scrolling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .scrolling-container {
    white-space: nowrap;
  }
  
  .scrolling-container > div {
    display: inline-block;
    margin-right: 10px;
  }

  


    .item {
        margin-right: 10px;
    }